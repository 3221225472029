//import SUBSCRIBE_CHAT_ACTIVITY from '@/graphql/subscriptions/onChatActivity';
//import SUBSCRIBE_GROUP_ACTIVITY from '@/graphql/subscriptions/onGroupActivity';
import SUBSCRIBE_USER_ACTIVITY from '@/graphql/subscriptions/onUserActivity';

import phoenixClient from '@/graphql/clients/phoenix';
import pcpClient from '@/graphql/clients/pcp';
//import { displayNotification } from '@/methods/notificationSystem';


import store from '@/store'


class SubscriptionService {
    constructor () {
        this.userID = null;
//        this.chatActivitySubscribed  = null;
//        this.onChatActivityInitialized = false;
//        this.retryOnChatActivity = null;

//        this.groupID = null
//        this.groupActivitySubscribed  = null;
//        this.onGroupActivityInitialized = false;
//        this.retryOnGroupActivity = null;

        //this.userID = null;
        this.userActivitySubscribed  = null;
        this.onUserActivityInitialized = false;
        this.retryOnUserActivity = null;

    }

    init ({ userID, groupID }) {

        this.userID = userID;
//        this.groupID = groupID;
        // terminate any previous subscriptions
        this.terminateAll();

        if (this.userID) {
//            this.initializeOnChatActivity();
            this.initializeOnUserActivity();
        }
//        this.initializeOnGroupActivity();

    }

    async initializeOnGroupActivity () {
        // initialize chat activity
        this.onGroupActivityInitialized = true;
        const groupActivity = pcpClient.subscribe({
            query: SUBSCRIBE_GROUP_ACTIVITY,
            variables: { group_id: this.groupID }
        })
        // store.dispatch(ycmdChatPostGet_update());
        this.groupActivitySubscribed = groupActivity.subscribe({
            next: async ( {data} ) => {
                console.log('group subscription data: ', data)                
                if (data && data.onGroupActivity && data.onGroupActivity.activity) {
                    switch (data.onGroupActivity.activity.type) {
                        case 'message_update':
                            let badgeTypes = this.checkBadgeMessageTypes(data.onGroupActivity)
                            store.dispatch('app/syncBadges', {type: badgeTypes}, {root:true})
                            break

                        case 'voicemail_update':
                            store.dispatch('app/syncBadges', {type: 'voicemail'}, {root:true})
                            break                            
                    }
                }
            },
            error: (err) => {
                console.error('group SUBSCRIPTION ERROR: ', err);
                this.reconnectOnGroupActivity();
            },
            complete: () => {
                console.log('group SUBSCRIPTION COMPLETED!');
            }
        })
        console.log('SUBSCRIBED: ', this.groupActivitySubscribed);
    }

    reconnectOnGroupActivity () {
        // terminate if there is an existing connection
        this.terminateGroupActivity();
        // start up init again
        // set timer
        this.timer = setTimeout(() => {
            //Vue.toasted.info(`Attempting to reconnect chat...`);
            this.initializeOnGroupActivity();
        }, 8000);
    }

    terminateGroupActivity () {
        // clear initializer
        this.onGroupActivityInitialized = false;
        // clear timer
        this.clearOnGroupActivityTimer();
        // shut down websocket connection
        if (this.groupActivitySubscribed) this.groupActivitySubscribed.unsubscribe()
    }

    clearOnGroupActivityTimer () {
        if (this.retryOnGroupActivity) clearTimeout(this.retryOnGroupActivity)
    }
    
    async initializeOnChatActivity () {
        // initialize chat activity
        this.onChatActivityInitialized = true;
        const chatActivity = pcpClient.subscribe({
            query: SUBSCRIBE_CHAT_ACTIVITY,
            variables: { user_id: this.userID }
        })
        // update room messages (TODO: this could be done better)
        await store.dispatch('rightMenu/closeRightMenu');
        await store.dispatch('rightMenu/openRightMenu');
        // store.dispatch(ycmdChatPostGet_update());
        this.chatActivitySubscribed = chatActivity.subscribe({
            next: async ({ data }) => {

                store.dispatch('chat/subscriptionReceived');
                console.log('INFO: Received subscription data for chat!');
                console.log('subscription data: ', data)
                const currentRoomID = store.getters["chat/getCurrentRoom"].id;

                //const currentRoute = routerRightMenu && routerRightMenu.currentRoute && routerRightMenu.currentRoute.name ? routerRightMenu.currentRoute.name : null;
                // // update badges
                // store.dispatch('chat/getBadgeCount');
                if (data && data.onChatActivity && data.onChatActivity.activity) {
                    // check if this is the first subscription message after establishing connection
                    if (this.onChatActivityInitialized) {
                        // if it is, fetch initial load messages incase we missed some while the subscription was down
                        // NOTE, we do this because the complete() method does not work, so we can't fetch messages right after the sub is established
                        // store.dispatch(ycmdChatPostGet_update());
                        // toggle off so it does not run again
                        this.onChatActivityInitialized = false;
                    }

                    const activity = data.onChatActivity.activity;
                    const postData = activity.post || {};
                    const roomData = activity.room || {};
                    const type = activity.type;

                    //store.dispatch('chat/addLastMessage', activity);

                    store.dispatch('app/syncBadges', {from: 'subscription', type: 'chat_post'})

                    let isChatOpen = store.getters['chat/getChatOpened']
                    console.log('isChatOpen', isChatOpen)
                    //if chat is opened, we don't load the current room because they may be interacting with the rooms

                    let title
                    let body

                    switch (type) {
                        case 'post':
                            title = roomData.name ? roomData.name : `${activity.first_name} ${activity.last_name}`;
                            body = `${activity.first_name} ${activity.last_name} posted in ${roomData.name ? roomData.name : 'a conversation'}`;
                            console.log('NOTIFICATION API: Displaying notificaiton');

                            if (!isChatOpen) {
                                store.dispatch('chat/loadCurrentRoom', activity.room)
                            } else {

                                if (activity.room.id === currentRoomID) {
                                    store.commit('chat/addPostToCurrentRoom', postData);
                                }
                            }
                            displayNotification(title, body, () => {

                            });
                        break;
                        case 'post_reaction':

                        break;
                        case 'room_created':
                            title = roomData.name ? roomData.name : `${activity.first_name} ${activity.last_name}`;
                            body = `${activity.first_name} ${activity.last_name} posted in ${roomData.name ? roomData.name : 'a conversation'}`;
                            console.log('NOTIFICATION API: Displaying notificaiton');

                            if (!isChatOpen) {
                                store.dispatch('chat/loadCurrentRoom', activity.room)
                            }
                            displayNotification(title, body, () => {
                            });

                        break;
                    }                    

                    // console.log('CURRENT ROOM ID: ', currentRoomID);
                    // console.log('CURRENT ROUTE ON RIGHT MENU: ', currentRoute);

                    

/*                    
                    if (currentRoomID === roomData.id && currentRoute === 'CHAT.main') {
                        // if we are inside that chat room
                        store.dispatch('chat/addRoomPost', postData);
                        await store.dispatch('chat/clearUnreadCount', roomData.id);
                        store.dispatch('chat/getBadgeCount');
                    } else {
                        // if we are not in that room update badge count
                        store.dispatch('chat/getBadgeCount');
                    }
                    // notification API check here
                    if (currentRoomID === roomData.id && currentRoute === 'CHAT.main' && !document.hidden) {
                        // if we are on the same room and the window is not hidden
                        // do nothing
                    } else {
                        // onPress method if they click the OS level notification
                        async function onPress() {
                            // only set roomand navigate if it's a new one or we aren't on the main chat page
                            // NOTE: This is basically to bypass clicking while on the same room if document is hidden
                            if (currentRoomID !== roomData.id || currentRoute !== 'CHAT.main') {
                                await store.dispatch('chat/setCurrentRoom', {
                                    id: roomData.id,
                                    name: roomData.name
                                });
                                await store.dispatch('rightMenu/routeRightMenu', {name: 'CHAT.main', transition: 'next'});
                            }
                            // await store.dispatch('rightMenu/closeRightMenu');
                            // await store.dispatch('rightMenu/openRightMenu');
                        }
                        // display notification (TODO: only do this if app is backgrounded?)
                        switch (type) {
                            case 'post':
                                const title = roomData.name ? roomData.name : `${activity.first_name} ${activity.last_name}`;
                                const body = `${activity.first_name} ${activity.last_name} posted in ${roomData.name ? roomData.name : 'a conversation'}`;
                                console.log('NOTIFICATION API: Displaying notificaiton');
                                displayNotification(title, body, onPress);
                            break;
                            case 'post_reaction':

                            break;
                            case 'room_created':

                            break;
                        }
                    }
*/                    
                }
            },
            error: (err) => {
                console.error('SUBSCRIPTION ERROR: ', err);
                this.reconnectOnChatActivity();
            },
            complete: () => {
                console.log('SUBSCRIPTION COMPLETED!');
            }
        })
        console.log('SUBSCRIBED: ', this.chatActivitySubscribed);
    }

    terminateChatActivity () {
        // clear initializer
        this.onChatActivityInitialized = false;
        // clear timer
        this.clearOnChatActivityTimer();
        // shut down websocket connection
        if (this.chatActivitySubscribed) this.chatActivitySubscribed.unsubscribe()
    }

    ///////////////////////
    /////// TIMERS ////////
    ///////////////////////

    clearOnChatActivityTimer () {
        if (this.retryOnChatActivity) clearTimeout(this.retryOnChatActivity)
    }

    clearTimeouts () {
        this.clearOnChatActivityTimer();
        this.clearOnGroupActivityTimer();
        this.clearOnUserActivityTimer();
    }

    ///////////////////////
    ///////////////////////
    ///////////////////////

    clearInitializers () {
        this.onChatActivityInitialized = false;
        this.onGroupActivityInitialized = false;
        this.onUserActivityInitialized = false;
    }

    reconnectOnChatActivity () {
        // terminate if there is an existing connection
        this.terminateChatActivity();
        // start up init again
        // set timer
        this.timer = setTimeout(() => {
            //Vue.toasted.info(`Attempting to reconnect chat...`);

            this.initializeOnChatActivity();
        }, 8000);
    }

    terminateAll() {
        // when you are logging out
        this.terminateChatActivity();
        this.terminateUserActivity();
        this.terminateGroupActivity();
    }

    checkBadgeMessageTypes(activity) {
        let userId = this.userID
        let groupId = this.groupID
        let messages
        if (activity && activity.activity && activity.activity.data) {
            messages = JSON.parse(activity.activity.data)
        }

        let badgeTypes = {}
        if (messages) {
            messages.map(m => {
                console.log('m', m)
                let isForwarding = false
                let isUser = false

                if (m.folders && m.folders.length) {
                    m.folders.map(f => {

                        for (let uid in f){
                            if (uid === userId) {
                                if (f[uid] == 1) { //current user inbox.  need to sync inbox
                                    isUser = true
                                }
                            } else if (uid === groupId) {
                                if (f[uid] == 1) {
                                    isForwarding = true
                                }
                            }
                        }
                    })
                }
                let confirmed = m.confirmed_by ? true : false

                if (isUser) {
                    if (!badgeTypes['message']) {
                        badgeTypes['message'] = {}
                    }
                    if (!confirmed) {
                        badgeTypes['message']['notification'] = true
                    }

                } else if (isForwarding) {

                    if (!badgeTypes['message_forwardcenter']) {
                        badgeTypes['message_forwardcenter'] = {}
                    }
                    if (!confirmed) {
                        badgeTypes['message_forwardcenter']['notification'] = true
                    }

                } else {
                    /*
                    if (!badgeTypes['message_group']) {
                        badgeTypes['message_group'] = {}
                    }
                    */
                    //never notify for group message
                }
            })
        }
        /*
        let badgeTypeString = ''
        for (let type in badgeTypes) {
            if (badgeTypeString) {
                badgeTypeString+='|'
            }
            badgeTypeString+=type
        }
        */
        return badgeTypes
    }








    async initializeOnUserActivity () {

        // initialize chat activity
        this.onUserActivityInitialized = true;
        const userActivity = pcpClient.subscribe({
            query: SUBSCRIBE_USER_ACTIVITY,
            variables: { user_id: this.userID }
        })
        // store.dispatch(ycmdChatPostGet_update());
        this.userActivitySubscribed = userActivity.subscribe({
            next: async ({ data }) => {

                console.log('user subscription activity', data)
                debugger
                let activity = data && data.onUserActivity && data.onUserActivity.activity ? data.onUserActivity.activity : null;;
                console.log('activity', activity, activity.type)

                if (activity) {
                    switch (activity.type){
                        case 'asynchronous_report':
                            debugger
                            store.dispatch('report/updateJob', activity, {root:true})
                            break
                        case 'asynchronous_report_progress':
                            debugger
                            store.dispatch('report/updateJobProgress', activity, {root:true})
                            break
                    }                        
                }
            },
            error: (err) => {
                console.error('SUBSCRIPTION ERROR: ', err);
                this.reconnectOnUserActivity();
            },
            complete: () => {
                console.log('SUBSCRIPTION COMPLETED!');
            }
        })
        console.log('SUBSCRIBED: ', this.chatActivitySubscribed);
    }

    terminateUserActivity () {
        // clear initializer
        this.onUserActivityInitialized = false;
        // clear timer
        this.clearOnUserActivityTimer();
        // shut down websocket connection
        if (this.userActivitySubscribed) this.userActivitySubscribed.unsubscribe()
    }

    ///////////////////////
    /////// TIMERS ////////
    ///////////////////////

    clearOnUserActivityTimer () {
        if (this.retryOnUserActivity) clearTimeout(this.retryOnUserActivity)
    }

    reconnectOnUserActivity () {
        // terminate if there is an existing connection
        this.terminateUserActivity();
        // start up init again
        // set timer
        this.timer = setTimeout(() => {
            //Vue.toasted.info(`Attempting to reconnect chat...`);

            this.initializeOnUserActivity();
        }, 8000);
    }


    get userActivityInitialized () {
        return this.onUserActivityInitialized
    }

}

export default new SubscriptionService()
