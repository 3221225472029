<template>

    <div class="view ycmd-blue-gradient">

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card"
                >

            <div class="i-card__main pa-2">
                <div class="i-card__main__content white br-2 pa-2">
                    <div class="i-card__scrollable ycmd-light-gray full-width full-height">

                        <v-container
                            fluid
                        >

                            <v-row
                                class="pl-0 pr-0 pb-0 pt-5 align-center"
                            >
                                <v-col
                                    class="pa-0 shrink"
                                >                     
                                    <v-select
                                        light dense flat outlined
                                        v-model="groupId"
                                        :items="filteredGroups"
                                        label="Groups"
                                        item-text="name"
                                        item-value="id"
                                        class="pr-5 align-center"
                                        style="width: 400px;"
                                        background-color="white"
                                        clearable
                                    >
                                        <template slot="append-outer">
                                            <v-checkbox
                                                class="d-flex align-center ma-0"
                                                v-model="filterOnlyIvr"
                                            >
                                                <template slot="label">
                                                    <span class="pb-5">Only IVR</span>
                                                </template>
                                            </v-checkbox>
                                        </template> 
                                    </v-select>
                                </v-col>
                                <v-col
                                    class="pa-0 shrink"
                                >                     
                                    <v-select
                                        light dense flat outlined
                                        v-model="selectedNumber"
                                        :items="numbers"
                                        label="Connect Number"
                                        item-text="text"
                                        item-value="value"
                                        class=""
                                        style="width: 400px;"
                                        background-color="white"
                                        append-outer-icon= 'add'
                                        @click:append-outer="addVoicemail"
                                        :disabled="!this.groupId"
                                        return-object
                                    >
                                    <template slot="append-outer">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    icon
                                                    @click="addVoicemail"
                                                    :disabled="sending"
                                                >
                                                    <v-icon                                            
                                                    >add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{addNumberLabel}}</span>
                                        </v-tooltip>

                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    icon
                                                    @click="deleteVoicemail"
                                                    :disabled="sending || !selectedNumber || !selectedVoicemail"
                                                >
                                                    <v-icon
                                                        color="error"
                                                    >delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Delete Voicemail</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    icon
                                                    @click="claimNumber"
                                                >
                                                    <v-icon
                                                    >add_ic_call</v-icon>
                                                </v-btn>                                        
                                            </template>
                                            <span>Claim an additional number</span>
                                        </v-tooltip>


                                    </template>                                    
                                    </v-select>
                                </v-col>
                            </v-row>

                            <v-row
                                class="px-0 pt-0 pb-5 align-center"
                            >
                                <v-col
                                    class="pt-0 pb-0 pl-0 pr-5 shrink"
                                >                     
                                    <v-btn
                                        depressed
                                        light
                                        class="ycmd-blue white--text"
                                        @click="saveVoicemail"
                                        :disabled="operationsDisabled"
                                        style="width: 180px;"
                                    >Save</v-btn>
                                </v-col>
                                <v-col
                                    class="pa-0 shrink"
                                >                     
                                    <v-btn
                                        depressed
                                        light
                                        class="ycmd-blue white--text"
                                        @click="startBackup"
                                        :disabled="operationsDisabled"
                                        style="width: 180px;"
                                    >Backup</v-btn>
                                </v-col>
                                <v-col
                                    class="pt-0 pb-0 pl-5 shrink"
                                >                     
                                    <v-btn
                                        depressed
                                        light
                                        class="ycmd-blue white--text"
                                        @click="startRestore"
                                        :disabled="operationsDisabled || !backups || !backups.length"
                                        style="width: 180px;"
                                    >Restore</v-btn>
                                </v-col>
                                <!--
                                <v-col
                                    class="pt-0 pb-0 pl-5 shrink"
                                >                     
                                    vm-{{selectedVoicemail}}
                                </v-col>
                                -->
                            </v-row>
                        </v-container>


                    <v-tabs
                        center-active
                        light
                        v-model="tab"
                        :key="`tab_${refreshKey}`"
                    >
                        <v-tab class="tab" active-class="tabs--active" :disabled="tabsDisabled">VOICEMAIL</v-tab>
                        <v-tab class="tab" active-class="tabs--active" :disabled="tabsDisabled">PROMPTS</v-tab>
                        <v-tab class="tab" active-class="tabs--active" :disabled="tabsDisabled">MENUS</v-tab>
                        <v-tab class="tab" active-class="tabs--active" :disabled="tabsDisabled">CUSTOMER DATA</v-tab>
                        <v-tab class="tab" active-class="tabs--active" :disabled="tabsDisabled">SECURITY</v-tab>
                        
                        <v-tabs-items v-model="tab">
                            <v-tab-item
                            v-if="!tabsDisabled"
                            >
                                <v-row
                                    class="pt-5"
                                >
                                    <v-col
                                        class="shrink"
                                    >
                                        <v-select
                                            light dense flat outlined
                                            v-model="selectedVoicemail.language"
                                            :items="languages"
                                            label="Language"
                                            item-text="text"
                                            item-value="value"
                                            class="pr-5"
                                            style="width: 200px;"
                                            background-color="white"
                                        ></v-select>
                                    </v-col>
                                    <v-col
                                        class="shrink"
                                    >
                                        <v-select
                                            light dense flat outlined
                                            v-model="selectedVoicemail.voice"
                                            :items="voices"
                                            label="Voice"
                                            item-text="text"
                                            item-value="value"
                                            class="pr-5"
                                            style="width: 200px;"
                                            background-color="white"
                                        ></v-select>
                                    </v-col>
                                    <v-col
                                        class="shrink"
                                    >
                                        <v-select
                                            light dense flat outlined
                                            v-model="selectedVoicemail.entry_point"
                                            :items="entryPoints"
                                            label="Entry Point"
                                            item-text="text"
                                            item-value="value"
                                            class="pr-5"
                                            style="width: 300px;"
                                            background-color="white"
                                            @change="entryPointChanged"
                                        ></v-select>
                                    </v-col>
                                    <v-col
                                        class="shrink"
                                    >
                                        <v-text-field
                                            label="Phone Number"
                                            light dense flat outlined
                                            v-model="selectedVoicemail.entry_value"
                                            style="width: 200px;"
                                            background-color="white"
                                            v-if="selectedVoicemail.entry_point == 'transfer_to_number'"
                                        />
                                        <v-select
                                            light dense flat outlined
                                            v-model="selectedVoicemail.entry_value"
                                            :items="allMenus"
                                            label="Entry Value"
                                            item-text="name"
                                            item-value="name"
                                            class=""
                                            style="width: 200px;"
                                            background-color="white"
                                            v-else-if="selectedVoicemail.entry_point == 'menu'"
                                        ></v-select>
                                        <v-text-field
                                            label="Mailbox"
                                            light dense flat outlined
                                            v-model="selectedVoicemail.entry_value"
                                            style="width: 300px;"
                                            background-color="white"
                                            v-else-if="selectedVoicemail.entry_point == 'voicemail'"
                                        />

                                    </v-col>                                
                                </v-row>

                                <v-row
                                    class="pt-0"
                                    v-if="isPrimary"
                                >
                                    <v-col
                                        class="shrink"
                                    >
                                        <v-text-field
                                            light dense flat outlined
                                            v-model="notificationSMSNumber"
                                            :rules="[]"
                                            background-color="white"
                                            label="Notification SMS Number"
                                            style="width: 182px;"
                                        />
                                    </v-col>
                                    <v-col
                                        class="shrink pl-5"
                                    >
                                        <v-text-field
                                            light dense flat outlined
                                            v-model="notificationEmailAddress"
                                            :rules="[]"
                                            background-color="white"
                                            label="Notification Email Address"
                                            style="width: 190px;"
                                            class="pl-2"
                                        />

                                    </v-col>                                
                                </v-row>

                            </v-tab-item>
                            <v-tab-item
                                v-if="!tabsDisabled"
                            >
                                <template
                                    v-if="isPrimary"
                                >

                                    <ivr-prompt
                                        class="pt-5"
                                        :language="selectedVoicemail.language"
                                        :prompt="selectedVoicemail.greeting"
                                        label="Greeting"
                                    />

                                    <ivr-prompt
                                        v-for="prompt in prompts"
                                        :language="selectedVoicemail.language"
                                        :key="prompt.key"
                                        :prompt="prompt.prompt"
                                        :label="prompt.description"
                                    />
                                </template>
                                <template
                                    v-else
                                >
                                    <ivr-additional-greeting
                                        :language="selectedVoicemail.language"
                                        :greeting="selectedVoicemail.greeting"
                                        :mainGreeting="selectedGroup.voicemail.greeting"
                                        label="Greeting"
                                        @greetingChanged="additionalGreetingChanged"
                                    />

                                    <ivr-additional-prompts
                                        :language="selectedVoicemail.language"
                                        :prompts="customPrompts"
                                        :allPrompts="selectedVoicemail.defaultPrompts"
                                        @promptsChanged="additionalPromptsChanged"
                                    />
                                </template>
                            </v-tab-item>
                            <v-tab-item
                                v-if="!tabsDisabled"
                            >
                                <v-row
                                    class="pt-5"
                                >
                                    <v-col
                                        cols="12"
                                    >                     
                                        <v-select
                                            light
                                            dense
                                            flat
                                            outlined
                                            v-model="selectedMenu"
                                            :items="menus"
                                            label="Menu"
                                            item-text="name"
                                            class=""
                                            style="width: 400px;"
                                            background-color="white"
                                            return-object
                                        >
                                            <template slot="append-outer">
                                                <v-icon
                                                    v-if="true"
                                                    @click="addMenu"
                                                >add</v-icon>
                                                <v-icon
                                                    v-if="!isPrimary"
                                                    @click="copyMenu"
                                                >content_copy</v-icon>
                                                <v-icon
                                                    v-if="selectedMenu"
                                                    @click="deleteMenu"
                                                    color="error"
                                                >delete</v-icon>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row
                                    class="pt-0 pb-5"
                                >
                                    <v-col
                                        cols="12"
                                        class="py-0"
                                    >
                                        <ivr-menu
                                            :language="selectedVoicemail.language"
                                            :menu="selectedMenu"
                                            :menus="menus"
                                            :key="`tab_${refreshKey}`"
                                            @changed="menusChanged"
                                        />
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item
                                class="pt-5"
                                v-if="!tabsDisabled"
                            >
                                <ivr-metadata
                                    :language="selectedVoicemail.language"
                                    :metadata="metadata"
                                    @metadataChanged="metadataChanged"
                                />
                            </v-tab-item>
                            <v-tab-item
                                class="pt-5"
                                v-if="!tabsDisabled"
                            >
                                <ivr-security
                                    :groupId="groupId"
                                    :key="groupId"
                                    :allMailboxes="allMailboxes"
                                    :mailboxes="this.selectedGroup && this.selectedGroup.voicemail ? this.selectedGroup.voicemail.mailboxes : null" 
                                    @changed="mailboxesChanged"
                                />
                            </v-tab-item>
                        </v-tabs-items>
                        
                    </v-tabs>
                    </div>
                </div>
            </div>

        </i-card>
        <v-dialog v-model="dialog" persistent max-width="300">

            <v-card style="padding-top: 15px;">
                <v-card-text>{{addNumberLabel}}</v-card-text>
                <v-card-text class="fs-18">
                    <!--
                    <v-text-field
                        light dense flat outlined
                        v-model="newNumber"
                        :rules="[]"
                        background-color="white"
                        :label="newNumberLabel"
                    />
                    -->
                    <v-select
                        light dense flat outlined
                        v-model="newNumber"
                        :items="availableNumbers"
                        label="Available numbers"
                        style="max-width: 800px;"
                    ></v-select>
                    
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn text @click="doAddVoicemail" :disabled="sending" >OK</v-btn>
                    <v-btn text @click="dialog = false" :disabled="sending">Cancel</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="backup" persistent max-width="300">
            <v-card style="padding-top: 15px;">
                <v-card-text>Backup</v-card-text>
                <v-card-text class="fs-18">
                    
                    <v-text-field
                        light dense flat outlined
                        v-model="backupDescription"
                        label="Description"
                        style="width: 300px;"
                    />
                    
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn text @click="doBackup" :disabled="sending" >OK</v-btn>
                    <v-btn text @click="backup = false" :disabled="sending">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>  
         
        <v-dialog v-model="restore" persistent max-width="300">
            <v-card style="padding-top: 15px;">
                <v-card-text>Restore</v-card-text>
                <v-card-text class="fs-18">
                    <v-select
                        light dense flat outlined
                        v-model="restoreId"
                        :items="backups"
                        label="Backups"
                        item-text="name"
                        item-value="id"
                        style="max-width: 800px;"
                    ></v-select>
                    
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn text @click="doRestore" :disabled="sending">OK</v-btn>
                    <v-btn text @click="restore = false" :disabled="sending">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>  

        <v-dialog v-model="menuDialog" persistent max-width="300">
            <v-card style="padding-top: 15px;">
                <v-card-text>New Menu</v-card-text>
                <v-card-text class="fs-18">
                    
                    <v-text-field
                        light dense flat outlined
                        v-model="menuKey"
                        label="menu key"
                        style="width: 300px;"
                        background-color="white"
                    />
                    
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn text @click="doAddMenu">OK</v-btn>
                    <v-btn text @click="menuDialog = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="confirmDelete" persistent max-width="300">
            <v-card style="padding-top: 15px;">
                <v-card-text>Delete Menu</v-card-text>

                <v-card-subtitle>Are you sure you want to delete this menu?</v-card-subtitle>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn text @click="doDeleteMenu">OK</v-btn>
                    <v-btn text @click="confirmDelete = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="confirmDeleteNumber" persistent max-width="300">
            <v-card style="padding-top: 15px;">
                <v-card-text>Delete Number</v-card-text>

                <v-card-subtitle>{{`Are you sure you want to delete ${this.isPrimary ? 'Voicemail' : 'Number'} for ${this.selectedGroup ? this.selectedGroup.name : ''}`}}</v-card-subtitle>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn text @click="doDeleteVoicemail">OK</v-btn>
                    <v-btn text @click="confirmDeleteNumber = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="promptMenuCopy" persistent max-width="300">
            <v-card style="padding-top: 15px;">
                <v-card-text>Copy Menu</v-card-text>

                <v-card-subtitle>Select the menu to copy</v-card-subtitle>

                <v-card-text>

                    <v-select
                        light dense flat outlined
                        v-model="copyMenuKey"
                        :items="mainMenus"
                        label="Entry Value"
                        item-text="name"
                        item-value="name"
                        class=""
                        style="width: 200px;"
                        background-color="white"
                    ></v-select>

                    <v-text-field
                        light dense flat outlined
                        v-model="newMenuKey"
                        label="New Menu key"
                        style="width: 300px;"
                        background-color="white"
                    />

                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn text @click="doCopyMenu" :disabled="!this.copyMenuKey || !this.newMenuKey">OK</v-btn>
                    <v-btn text @click="promptMenuCopy = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="promptClaimNumber" persistent max-width="300">
            <v-card style="padding-top: 15px;">
                <v-card-text>Claim a number</v-card-text>

                <v-card-subtitle>Enter the area code you would like to claim</v-card-subtitle>

                <v-card-text>

                    <v-text-field
                        light dense flat outlined
                        v-model="claimNumberAreaCode"
                        label="Area Code"
                        style="width: 300px;"
                        background-color="white"
                        @keydown="beforeAreacodeChange"
                        @keyup="areacodeChanged"
                        :disabled="sending"
                    />
                    <v-progress-linear indeterminate color="primary" class="" v-if="sending" />
                    <div
                        style="height: 200px;overflow-y: scroll;"
                    >
                        
                        <v-list
                            dense
                            v-if="searchAreaCodeFormattedNumbers && searchAreaCodeFormattedNumbers.length"
                        >
                            <v-list-item
                                v-for="item in searchAreaCodeFormattedNumbers"
                                :key="item.number"
                            >
                                <v-list-item-content
                                    @click="selectClaimNumber(item)"
                                    style="cursor: pointer;"
                                >
                                    <v-list-item-title><v-icon>{{item.selected ? 'check_box' : 'check_box_outline_blank'}}</v-icon>{{item.number}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <label
                            class="v-label v-label--active theme--light text-caption"
                            v-else   
                        >    
                            No Numbers Available
                        </label>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-btn text @click="doLoadMore" v-if="searchAreaCodeNextToken" :disabled="sending">Load More</v-btn>
                    <v-spacer/>
                    <v-btn text @click="doClaimNumber" :disabled="sending">OK</v-btn>
                    <v-btn text @click="promptClaimNumber = false" :disabled="sending">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>

</template>

<script>
import {defaultErrorHandler} from '@/graphql/handler/errorHandler';
import SEARCH_GROUPS from '@/graphql/queries/phxGroups';
import ivrAdditionalGreeting from './ivrAdditionalGreeting'
import ivrAdditionalPrompts from './ivrAdditionalPrompts'
import ivrSecurity from './ivrSecurity'
import ivrPrompt from './ivrPrompt'
import ivrMenu from './ivrMenu'
import ivrMetadata from './ivrMetadata'
import ycmdVoicemailSettingsUpdate from '@/graphql/mutations/phoenix/ycmdVoicemailSettingsUpdate';
import { removePropertiesFromObject } from '@/methods/globalMethods';
import { format } from 'date-fns';

export default {
    props: {
    },
    data() {
        return {
            card: {
                index: 0,
                panels: { left: false, right: false, top: false, bottom: false }
            },
            groups: [],
            groupId: null,
            selectedNumber: null,
            dialog: false,
            newNumberLabel: "Phone Number",
            newNumber: '',
            voices: [{text: 'Female', value: 'female'}, {text: 'Male', value: 'male'}],
            languages: [{text: 'Prompt', value: ''}, {text: 'English', value: 'en-US'}, {text: 'Spanish', value: 'es-US'}],
            voicemailMain: {},
            entryPoints: [
                {value: 'menu', text: 'Menu'},
                {value: 'voicemail', text: 'Voicemail'},
                {value: 'transfer_to_twilio', text: 'Transfer to YoucallMd'},
                {value: 'transfer_to_number', text: 'Transfer to a Phone Number'}
            ],
            selectedMenu : {},
            tab: 0,
            refreshKey: 0,
            metadataToSave: [],
            // additionalMetadataToSave: {},
            metadataInitialized: false,
            backup: false,
            backupDescription: '',
            restore: false,
            restoreId: null,
            sending: false,
            prompts: [],
            menuDialog: false,
            menuKey: '',
            confirmDelete: false,
            promptMenuCopy: false,
            copyMenuKey: null,
            newMenuKey: null,
            notificationSMSNumber: '',
            notificationEmailAddress: '',
            additional_numbers: [],
            customPrompts: [],
            metadata: [],
            menus: [],
            availableNumbers: [],
            confirmDeleteNumber: false,
            allMailboxes: [],
            mailboxesToSave: null,
            filterOnlyIvr: false,
            promptClaimNumber: false,
            claimNumberAreaCode: '',
            searchAreaCode: '',
            searchAreaCodeNextToken: '',
            searchAreaCodeNumbers:[],
            searchAreaCodeNumber: ''
        }
    },
    activated() {

    },
    mounted() {
        this.getGroups();
    },
    components: {
        'ivr-security': ivrSecurity,
        'ivr-prompt': ivrPrompt,
        'ivr-menu': ivrMenu,
        'ivr-metadata': ivrMetadata,
        'ivr-additional-prompts': ivrAdditionalPrompts,
        'ivr-additional-greeting': ivrAdditionalGreeting
    },
    computed: {
        filteredGroups () {
            let arr = []

            let checkIvr = this.filterOnlyIvr
            this.groups.map(g => {
                if (!checkIvr || g.aws_connect_phone || g.voicemail) {
                    arr.push(g)
                }
            })
            return arr
        },
        allMenus () {
            let menus = {}
            if (this.selectedGroup && this.selectedGroup.voicemail && this.selectedGroup.voicemail.menus && this.selectedGroup.voicemail.menus.length) {
                this.selectedGroup.voicemail.menus.map(menu => {
                    menus[menu.name] = menu
                })
            }
            
            if (!this.isPrimary) {
                let additional_vm = this.additional_numbers.find(an => {
                    return an.phone_number === this.selectedNumber.value
                })
                if (additional_vm && additional_vm.voicemail && additional_vm.voicemail.menus && additional_vm.voicemail.menus.length) {
                    additional_vm.voicemail.menus.map(menu => {
                        menus[menu.name] = menu
                    })
                }                
            }

            let arr = []
            for (let m in menus) {
                arr.push(menus[m])
            }

            return arr
        },
        mainMenus () {
            let menus = {}
            if (this.selectedGroup && this.selectedGroup.voicemail && this.selectedGroup.voicemail.menus && this.selectedGroup.voicemail.menus.length) {
                this.selectedGroup.voicemail.menus.map(menu => {
                    menus[menu.name] = menu
                })
            }

            let arr = []
            for (let m in menus) {
                arr.push(menus[m])
            }

            return arr
        },
        isPrimary () {
            if (!this.selectedGroup) {
                return false
            }
            if (!this.selectedNumber) {
                return true
            }

            return this.selectedNumber.value === this.selectedGroup.aws_connect_phone
        },
        tabsDisabled () {
            if (!this.selectedVoicemail) {
                return true
            }

            return false

        },
        operationsDisabled () {
            if (!this.selectedVoicemail) {
                return true
            }
            if (this.sending) {
                return true
            }

            return false
        },
        /*
        mergedPrompts () {
            let arr = []
            let prompts = {}

            if (this.selectedVoicemail && this.selectedVoicemail.custom_prompts && this.selectedVoicemail.custom_prompts.length) {
                this.selectedVoicemail.custom_prompts.map(cp => {
                    prompts[`${cp.key}_${cp.language}`] = cp.text
                })
            }

            this.defaultPrompts.map(dp => {
                dp.prompt.map(p => {
                    let key = `${dp.key}_${p.language}`
                    let text = prompts[key]
                    p.defaultText = p.text
                    if (text) {
                        p.text = text
                    }
                })
                arr.push(dp)
            })

            return arr
        },

        defaultPrompts () {
            let arr = this.getCopyOfDefaults()

            return arr
        },
        */
        selectedGroup () {
            if (this.groupId) {
                return this.groups.find(g => {
                    return g.id == this.groupId
                })
            }
            return null
        },
        selectedVoicemail () {
            if (this.selectedGroup) {
                if (this.selectedGroup.voicemail && this.selectedGroup.voicemail.entry_point === 'menu' && !this.selectedGroup.voicemail.entry_value) {
                    // default to main menu
                    this.selectedGroup.voicemail.entry_value = 'main'
                }

                if (this.isPrimary) {
                    return this.selectedGroup.voicemail
                } else {
                    let additional_vm = this.additional_numbers.find(an => {
                        return an.phone_number === this.selectedNumber.value
                    })
                    if (additional_vm) {
                        return additional_vm.voicemail
                    }
                }
            }
            return null
        },
        backups () {
            let arr = []
            if (this.selectedGroup) {
                if (this.selectedGroup.voicemail_backups && this.selectedGroup.voicemail_backups.length) {
                    this.selectedGroup.voicemail_backups.map(vm => {
                        arr.push({
                            name: `${vm.description}-(${format(new Date(vm.date),"yyyy-MM-dd hh:mm")})`,
                            id: vm.id
                        })
                    }) 
                }
            }
            return arr
        },
        numbers () {
            let arr = []
            if (this.selectedGroup && this.selectedGroup.aws_connect_phone) {
                arr.push(
                    {text: `${this.selectedGroup.aws_connect_phone} (main)`, value: this.selectedGroup.aws_connect_phone}
                )
            }
            if (this.additional_numbers && this.additional_numbers.length) {
                this.additional_numbers.map(n => {
                    arr.push(
                        {text: `${n.phone_number} (additional)`, value: n.phone_number}
                    )
                })
            }

            return arr
        },
        addNumberLabel () {
            return this.selectedGroup && this.selectedGroup.aws_connect_phone ? 'Add another number' : 'Set up voicemail'
        },
        voicemail () {
            if (this.selectedVoicemail) {
                return this.selectedVoicemail
            }

            return {}
        },
        searchAreaCodeFormattedNumbers () {
            let arr = []
            let i = 0
            this.searchAreaCodeNumbers.map(n => {
                arr.push({
                    number: n,
                    selected: n === this.searchAreaCodeNumber
                })
            })
            return arr
        }
        /*
        ,
        menus () {
            let arr = []
            if (this.selectedVoicemail && this.selectedVoicemail.menus && this.selectedVoicemail.menus.length) {
                this.selectedVoicemail.menus.map(m => {
                    arr.push(m)
                })
            }

            return arr
        },
        */
    },
    methods: {
        keyType (keyCode) {
            if (keyCode >= "0".charCodeAt(0) && keyCode <= "9".charCodeAt(0)) {
                return 'numeric'
            }
            if (keyCode >= "a".charCodeAt(0) && keyCode <= "z".charCodeAt(0)) {
                return 'alpha'
            }
            if (keyCode >= "A".charCodeAt(0) && keyCode <= "Z".charCodeAt(0)) {
                return 'alpha'
            }
        },
        areacodeChanged (ev) {
            if (this.claimNumberAreaCode.length == 3) {
                this.searchAreaCode = this.claimNumberAreaCode
            }
        },
        beforeAreacodeChange (ev) {
            let keytype = this.keyType(ev.keyCode)
            if (this.claimNumberAreaCode.length >= 3) {
                if (keytype) {
                    if (!ev.currentTarget.selectionEnd) {
                        ev.preventDefault()
                    }
                }
            } else {
                if (keytype === 'alpha') {
                    ev.preventDefault()
                }
            }
        },
        additionalGreetingChanged (greeting) {
            this.selectedVoicemail.greeting = greeting
        },
        mailboxesChanged (mailboxes) {
            this.mailboxesToSave = mailboxes
        },
        vmToMenus (voicemail) {
            let menus = []
            if (!voicemail) {
                return menus
            }

            if (voicemail.entry_point === 'voicemail' && voicemail.entry_value) {
                menus.push(voicemail.entry_value)
            }

            if (voicemail.menus && voicemail.menus.length) {
                voicemail.menus.map(m => {
                    if (m.options && m.options.length) {
                        m.options.map(o => {
                            if (o.action === 'voicemail' && o.action_value) {
                                menus.push(o.action_value)
                            }
                        })
                    }
                })
            }
            return menus
        },
        loadMailboxes () {
            let arr = []
            if (this.selectedGroup && this.selectedGroup.voicemail) {
                let key = {}
                
                let menus = this.vmToMenus(this.selectedGroup.voicemail)
                menus.map(m => {
                    key[m] = true
                })

                if (this.selectedGroup && this.selectedGroup.additional_numbers) {
                    for (let an in this.selectedGroup.additional_numbers) {
                        let number = this.selectedGroup.additional_numbers[an]
                        if (number) {
                            let menus = this.vmToMenus(number)
                            menus.map(m => {
                                key[m] = true
                            })
                        }
                    }
                }
                for (let mailbox in key) {
                    arr.push(mailbox)
                }
            }
            this.allMailboxes = arr
        },
        entryPointChanged (value) {
        },
        additionalPromptsChanged (prompts) {
            let additional_vm = this.additional_numbers.find(an => {
                return an.phone_number === this.selectedNumber.value
            })

            if (additional_vm) {
                additional_vm.voicemail.custom_prompts = prompts
            }
        },
        copyPrompts (forceRoot) {
            let arr = []
            let prompts = {}

            let promptsToCopy

            if (this.selectedVoicemail && this.selectedVoicemail.custom_prompts && this.selectedVoicemail.custom_prompts.length) {
                promptsToCopy = this.selectedVoicemail.custom_prompts
            }

            if (forceRoot) {
                promptsToCopy = this.selectedGroup.voicemail.custom_prompts
            }

            if (promptsToCopy && promptsToCopy.length) {
                promptsToCopy.map(cp => {
                    prompts[`${cp.key}_${cp.language}`] = cp.text
                })
            }

            let dps = this.getCopyOfDefaults()

            dps.map(dp => {
                dp.prompt.map(p => {
                    let key = `${dp.key}_${p.language}`
                    let text = prompts[key]
                    p.defaultText = p.text
                    if (text) {
                        p.text = text
                    }
                })
                arr.push(dp)
            })

            return arr
        },
        getCopyOfDefaults () {

        let arr = []

        //<say-as interpret-as="telephone">${customer_endpoint}</say-as>    $CUSTOMER_NUMBER
        //<say-as interpret-as="telephone">$.Attributes.callback_phone</say-as>     $CUSTOMER_ENTERED_PHONE
        //<prosody rate="slow">${group.name}</prosody>  $GROUP_NAME
            arr.push(
                {
                    key: 'prompt_pn_info',
                    prompt: [
                        {
                            language: 'en-US',
                            text: `Let's start by getting some basic information about you.  I see you are calling from $CUSTOMER_NUMBER. Would you like to use this as your call back number? Press 1 for yes. Press 2 to enter a new number.`
                        },
                        {
                            language: 'es-US',
                            text: `Comencemos por obtener información básica sobre usted.  Veo que estas llamando desde $CUSTOMER_NUMBER. ¿Le gustaría usar esto como su número de devolución de llamada?  Presione 1 para sí.  Presione 2 para ingresar un nuevo número.`
                        }
                    ],
                    description: 'Callback Number prompt'
                }
            )
            arr.push(
                {
                    key: 'prompt_cn_new_number',
                    prompt: [
                        {
                            language: 'en-US',
                            text: `Using your keypad on your phone, please enter a new callback number followed by the pound sign.`
                        },
                        {
                            language: 'es-US',
                            text: `Usando el teclado de su teléfono, ingrese un nuevo número de devolución de llamada seguido del símbolo de almohadilla.`
                        }
                    ],
                    description: 'Customer new callback number prompt'
                }
            )
            arr.push(
                {
                    key: 'prompt_pn_entered',
                    prompt: [
                        {
                            language: 'en-US',
                            text: `You entered the following number $CUSTOMER_ENTERED_PHONE.  Is this correct?  Press 1 for yes.  Press 2 to enter a new number.`
                        },
                        {
                            language: 'es-US',
                            text: `Ingresaste el siguiente número $CUSTOMER_ENTERED_PHONE.  ¿Es esto correcto?  Presione 1 para sí.  Presione 2 para ingresar un nuevo número.`
                        }
                    ],
                    description: 'Entered Callback Number confirmation'
                }
            )
            arr.push(
                {
                    key: 'prompt_cn_accept_sms',
                    prompt: [
                        {
                            language: 'en-US',
                            text: `Can you receive text messages at this number.  Press 1 for yes.  Press 2 for no.`
                        },
                        {
                            language: 'es-US',
                            text: `¿Puede recibir mensajes de texto en este número? Presione 1 para sí. Presione 2 para no.`
                        }
                    ],
                    description: 'Allow text messages prompt'
                }
            )

            arr.push(
                {
                    key: 'prompt_pn_additional',
                    prompt: [
                        {
                            language: 'en-US',
                            text: `You will be asked several questions.  Please speak your answers slowly and clearly.  When you are finished, you will have an opportunity to leave a message.  Press 1 to begin, or 2 to go back to the main menu.`
                        },
                        {
                            language: 'es-US',
                            text: `Se le harán varias preguntas. Diga sus respuestas lenta y claramente. Cuando haya terminado, tendrá la oportunidad de dejar un mensaje. Diga Sí para comenzar o No para volver al menú principal.`
                        }
                    ],
                    description: 'Customer data collection introduction'
                }
            )

            arr.push(
                {
                    key: 'prompt_rn_announce',
                    prompt: [
                        {
                            language: 'en-US',
                            text: `Now leaving a voicemail for: $GROUP_NAME`
                        },
                        {
                            language: 'es-US',
                            text: `Ahora dejando un mensaje de voz para: $GROUP_NAME`
                        }
                    ],
                    description: 'Leaving a voicemail introduction'
                }
            )

            arr.push(
                {
                    key: 'prompt_rn_instruct',
                    prompt: [
                        {
                            language: 'en-US',
                            text: `Please leave your message after the beep. Make sure to include your full name so we have the correct pronunciation. Note: You may end the recording at anytime by pressing the pound key.`
                        },
                        {
                            language: 'es-US',
                            text: `Deje su mensaje después del pitido. Asegúrese de incluir su nombre completo para que tengamos la pronunciación correcta. Nota: Puede finalizar la grabación en cualquier momento presionando el signo de almohadilla.`
                        }
                    ],
                    description: 'Leaving a voicemail instructions'
                }
            )

            arr.push(
                {
                    key: 'prompt_rn_confirm',
                    prompt: [
                        {
                            language: 'en-US',
                            text: `Your message has been sent.`
                        },
                        {
                            language: 'es-US',
                            text: `Tu mensaje ha sido enviado.`
                        }
                    ],
                    description: 'Voicemail sent confirmation'
                }
            )

            arr.push(
                {
                    key: 'prompt_rn_exit',
                    prompt: [
                        {
                            language: 'en-US',
                            text: `If you need further awesome assistance please feel free to call back anytime. Thank you for calling $GROUP_NAME. Goodbye.`
                        },
                        {
                            language: 'es-US',
                            text: `Si necesita más ayuda, no dude en llamarnos en cualquier momento. Gracias por llamar a $GROUP_NAME. Adiós.`
                        }
                    ],
                    description: 'Voicemail exit message'
                }
            )
            return arr
        },
        toastResponse(response, message) {
            let data = response && response.data && response.data.ycmdVoicemailSettingsUpdate

            if (data && data.status === 'success') {
                this.$toasted.success(message);
                return data
            } else {
                let error = "An error occurred"
                if (response.errors && response.errors.length) {
                    error = response.errors[0].message
                }
                this.$toasted.error(error);
            }
        },
        async doBackup () {
            this.sending = true

            let response = await this.$apollo.mutate({
                mutation: ycmdVoicemailSettingsUpdate,
                variables: {
                    group_id: this.groupId,
                    operation: "backup",
                    description: this.backupDescription
                },
                fetchPolicy: 'no-cache',
                errorPolicy: 'all'
            });
            console.log('response', response)

            let data = this.toastResponse(response, 'Voicemail backed up')

            if (data.status === 'success') {
                let newBackup = JSON.parse(data.data)
                if (!this.selectedGroup.voicemail_backups) {
                    this.selectedGroup.voicemail_backups = []
                }
                this.selectedGroup.voicemail_backups.push(newBackup)
            }
            this.sending = false

            this.backup = false
        },
        async doRestore () {
            this.sending = true
            let response = await this.$apollo.mutate({
                mutation: ycmdVoicemailSettingsUpdate,
                variables: {
                    group_id: this.groupId,
                    operation: "restore",
                    backup_id: this.restoreId
                },
                fetchPolicy: 'no-cache',
                errorPolicy: 'all'
            });
            await this.getGroups()

            this.groupId = null
            this.sending = false

            let data = this.toastResponse(response, 'Voicemail restored.  Please reload the group')

            this.restore = false
        },
        startRestore () {
            this.restoreId = null
            this.restore = true
        },
        startBackup () {
            this.backupDescription = ''
            this.backup = true
        },
        voicemailSaveObject () {
            let obj = {}

            for (let prop in this.selectedGroup.voicemail) {
                switch (prop) {
                    case 'additional_numbers':
                        break

                    default: 
                        obj[prop] = this.selectedGroup.voicemail[prop]
                        break
                }
            }
            obj.metadata = this.metadataToSave
            
            if (this.notificationSMSNumber) {
                obj.notify_sms_numbers = [this.notificationSMSNumber]
            } else {
                obj.notify_sms_numbers = null
            }

            if (this.notificationEmailAddress) {
                obj.notify_emails = [this.notificationEmailAddress]
            } else {
                obj.notify_emails = null
            }

            let defaultPrompt = {}
            let dprompts = this.getCopyOfDefaults()

            dprompts.map(dp => {
                dp.prompt.map(p => {
                    let key = `${dp.key}_${p.language}`
                    let text = p.text
                    defaultPrompt[key] = text
                })
            })

            let changed = []
            this.prompts.map(mp => {
                mp.prompt.map(p => {
                    let key = `${mp.key}_${p.language}`
                    let text = p.text
                    if (defaultPrompt[key] !== text) {
                        changed.push({
                            language: p.language,
                            text: text,
                            key: mp.key
                        })
                    }
                })
            })

            obj.custom_prompts = changed

            if (this.additional_numbers && this.additional_numbers.length) {
               obj.additional_numbers = [] 
                this.additional_numbers.map(number => {
                    obj.additional_numbers.push({
                        number: number.phone_number,
                        voicemail: number.voicemail
                    })
                })
            }

            obj.mailboxes = this.mailboxesToSave

            removePropertiesFromObject(obj, ['__typename','defaultPrompts'])

            return obj
        },
        async saveVoicemail () {
            this.sending = true

            let vmToSave = this.voicemailSaveObject()
            
            let response = await this.$apollo.mutate({
                mutation: ycmdVoicemailSettingsUpdate,
                variables: {
                    voicemailSettings: vmToSave,
                    group_id: this.groupId,
                    operation: "update"
                },
                fetchPolicy: 'no-cache',
                errorPolicy: 'all'
            });
            this.sending = false

            this.toastResponse(response, 'Voicemail saved.  Reload group')
            
            this.getGroups()
            this.groupId = null
        },
        metadataChanged (metadata) {
            this.metadataInitialized = true
            console.log('metadata', metadata)
            if (this.isPrimary) {
                this.metadataToSave = metadata
            } else {

                let additional_vm = this.additional_numbers.find(an => {
                    return an.phone_number === this.selectedNumber.value
                })

                if (additional_vm) {
                    additional_vm.voicemail.metadata = metadata
                }

                //this.additionalMetadataToSave[this.selectedNumber] = metadata
            }
        },
        menusChanged (menus) {
            if (this.isPrimary) {
                this.selectedGroup.voicemail.menus = menus
            } else {

                let additional_vm = this.additional_numbers.find(an => {
                    return an.phone_number === this.selectedNumber.value
                })

                if (additional_vm) {
                    additional_vm.voicemail.menus = menus
                }

                //this.additionalMetadataToSave[this.selectedNumber] = metadata
            }
            this.loadMailboxes()
        },
        addMenu () {
            this.menuKey = ''
            this.menuDialog = true
        },
        deleteMenu () {
            this.confirmDelete = true
        },
        copyMenu () {
            this.copyMenuKey = null
            this.newMenuKey = ''
            this.promptMenuCopy = true
        },
        doCopyMenu () {
            let menuToCopy = this.mainMenus.find(m => {
                return m.name === this.copyMenuKey
            })

            if (menuToCopy) {
                let str = JSON.stringify(menuToCopy)
                let newMenu = JSON.parse(str)
                newMenu.name = this.newMenuKey
                this.selectedVoicemail.menus.push(newMenu)

            }
            this.promptMenuCopy = false

        },
        doDeleteMenu () {
            let index = -1
            if (this.selectedMenu && this.selectedMenu.name) {
                index = this.selectedVoicemail.menus.findIndex(m => {
                    return this.selectedMenu.name === m.name
                })
            }
            if (index >= 0) {
                this.selectedVoicemail.menus.splice(index, 1)
                this.selectedMenu = this.selectedVoicemail.menus.length ? this.selectedVoicemail.menus[0]: null
            }

            this.confirmDelete = false
        },
        doAddMenu () {
            if (this.menuKey) {
                this.selectedVoicemail.menus.push({
                    name: this.menuKey,
                    introduction: [
                        {
                            language: 'en-US',
                            text: ''
                        },
                        {
                            language: 'es-US',
                            text: ''
                        },
                    ],
                    message: [
                        {
                            language: 'en-US',
                            text: ''
                        },
                        {
                            language: 'es-US',
                            text: ''
                        },
                    ],
                    options: []
                })
            }
            this.menuDialog = false
        },
        deleteVoicemail () {
            this.confirmDeleteNumber = true
        },
        async doDeleteVoicemail () {
            this.sending = true
            let variables = {
                group_id: this.groupId
            }

            if (this.isPrimary) {
                variables.operation = 'delete_primary'
            } else {
                variables.operation = 'delete_secondary',
                variables.aws_connect_phone = this.selectedNumber.value
            }

            let response = await this.$apollo.mutate({
                mutation: ycmdVoicemailSettingsUpdate,
                variables: variables,
                fetchPolicy: 'no-cache',
                errorPolicy: 'all'
            });
            console.log('response', response)
            let data = this.toastResponse(response, this.isPrimary ? 'Voicemail deleted.  Reload group': 'Number deleted.')

            if (data.status === 'success') {
                this.getGroups()
                this.groupId = null
            }
            this.sending = false
            this.confirmDeleteNumber = false
        },
        async claimNumber () {
            this.promptClaimNumber = true
        },
        selectClaimNumber (item) {
            if (!this.sending) {
                this.searchAreaCodeNumber = item.number
            }
        },
        async addVoicemail () {
            this.sending = true
            let response = await this.$apollo.mutate({
                mutation: ycmdVoicemailSettingsUpdate,
                variables: {
                    group_id: this.groupId,
                    operation: "available_numbers"
                },
                fetchPolicy: 'no-cache',
                errorPolicy: 'all'
            });
            let data = response && response.data && response.data.ycmdVoicemailSettingsUpdate

            this.availableNumbers = JSON.parse(data.data)
            this.newNumber = ''
            this.sending = false
            this.dialog = true
        },
        async doAddVoicemail () {
            this.sending = true

            if (this.selectedGroup.aws_connect_phone) {
                if (!this.additional_numbers) {
                    this.additional_numbers = []
                }

                let additional_vm = this.additional_numbers.find(an => {
                    return an.phone_number === this.newNumber
                })

                if (!additional_vm) {
                    this.additional_numbers.push(
                        {   phone_number: this.newNumber, 
                            voicemail: {
                                menus: [],
                                defaultPrompts: this.copyPrompts(true),
                                custom_prompts: [],
                                metadata: []
                            }
                        }
                    )
                    //this.additionalMetadataToSave[this.newNumber] = []
                    //this.additionalPrompts[this.newNumber] = []
                }
            } else {
                let response = await this.$apollo.mutate({
                    mutation: ycmdVoicemailSettingsUpdate,
                    variables: {
                        group_id: this.groupId,
                        operation: "new",
                        aws_connect_phone: this.newNumber
                    },
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                });
                console.log('response', response)
                let data = this.toastResponse(response, 'Voicemail created.  Reload group to edit')

                if (data.status === 'success') {
                    this.getGroups()
                    this.groupId = null
                }
            }

            this.sending = false
            this.dialog = false

        },
        doLoadMore () {
            this.doSearchAreaCode()
        },
        async doClaimNumber () {
            console.log('searchAreaCodeNumber', this.searchAreaCodeNumber)
            let response = await this.$apollo.mutate({
                mutation: ycmdVoicemailSettingsUpdate,
                variables: {
                    group_id: this.groupId,
                    operation: "claim_number",
                    aws_connect_phone: this.searchAreaCodeNumber
                },
                fetchPolicy: 'no-cache',
                errorPolicy: 'all'
            });
            
            this.toastResponse(response, 'Number has been claimed.')
        },
        async getGroups() {
            try {
                const response = await this.$apollo.query({
                    query: SEARCH_GROUPS,
                    variables: {
                        group_id: null
                    },
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                });

                if (response.data.phxGroups) {
                    // console.log('setting all groups: ', response.data.phxGroups);
                    this.groups = response.data.phxGroups.sort(function(a, b) {
                        return a.name.trim().localeCompare(b.name.trim());
                    });
                } 

            } catch (e) {
                this.isLoadingGroupsSearch = false;
                defaultErrorHandler(e);
            }
        },
        async doSearchAreaCode() {
            this.sending = true

            let response = await this.$apollo.mutate({
                mutation: ycmdVoicemailSettingsUpdate,
                variables: {
                    group_id: this.groupId,
                    operation: "search_numbers",
                    aws_connect_phone: this.searchAreaCode,
                    nextToken: this.searchAreaCodeNextToken
                },
                fetchPolicy: 'no-cache',
                errorPolicy: 'all'
            });

            if (response.errors && response.errors.length) {
                let error = response.errors[0].message
                if (!error) {
                    error = 'An error has occurred'
                }
                this.$toasted.error(error);
            } else {
                let data = response && response.data && response.data.ycmdVoicemailSettingsUpdate

                if (data && data.status === 'success') {
                    let json = JSON.parse(data.data)
                    this.searchAreaCodeNextToken = json.nextToken

                    console.log('json', json)
                    if (!this.searchAreaCodeNextToken) {
                        this.searchAreaCodeNumbers.splice(0)
                    }
                    json.numbers.map(n => {
                        this.searchAreaCodeNumbers.push(n)
                    })
                }

            }

            this.sending = false

        }
    },
    watch: {
        searchAreaCode (newValue, oldValue) {
            this.searchAreaCodeNumbers = []
            this.searchAreaCodeNextToken = null
            this.doSearchAreaCode()
        },
        numbers (newValue, oldValue) {
            if (newValue && newValue.length) {
                this.selectedNumber = newValue[newValue.length-1]
            } else {
                this.selectedNumber = null
            }
        },
        selectedNumber (newValue, oldValue) {
            this.selectedMenu = this.selectedVoicemail &&  this.selectedVoicemail.menus && this.selectedVoicemail.menus.length ? this.selectedVoicemail.menus[0] : null
            console.log('this.selectedVoicemail', this.selectedVoicemail)
            if (this.isPrimary && this.metadataInitialized) {
                this.metadata = this.metadataToSave
            }
            if (this.isPrimary) {
                this.menus = this.selectedVoicemail && this.selectedVoicemail.menus && this.selectedVoicemail.menus.length ? this.selectedVoicemail.menus: []
            } else {
                //this.selectedVoicemail.metadata = this.additionalMetadataToSave[newValue]

                // maybe change above too to look at this too
                let additional_vm = this.additional_numbers.find(an => {
                    return an.phone_number === newValue.value
                })

                if (additional_vm) {
                    this.customPrompts = additional_vm.voicemail.custom_prompts
                    this.metadata = additional_vm.voicemail.metadata
                    this.menus = additional_vm.voicemail.menus
                }
            }
        },
        groupId (newValue, oldValue) {
            if (newValue) {
                this.$nextTick(() => {
                    this.selectedMenu = this.selectedVoicemail &&  this.selectedVoicemail.menus && this.selectedVoicemail.menus.length ? this.selectedVoicemail.menus[0] : null
                    this.prompts = this.copyPrompts()
                    this.notificationSMSNumber = this.selectedVoicemail && this.selectedVoicemail.notify_sms_numbers && this.selectedVoicemail.notify_sms_numbers.length ? this.selectedVoicemail.notify_sms_numbers[0]: null
                    this.notificationEmailAddress = this.selectedVoicemail && this.selectedVoicemail.notify_emails && this.selectedVoicemail.notify_emails.length ? this.selectedVoicemail.notify_emails[0]: null
                    this.metadata = this.selectedVoicemail && this.selectedVoicemail.metadata && this.selectedVoicemail.metadata.length ? this.selectedVoicemail.metadata: []

                    this.metadataToSave = this.metadata                    
                    this.menus = this.selectedVoicemail && this.selectedVoicemail.menus && this.selectedVoicemail.menus.length ? this.selectedVoicemail.menus: []
                    this.additional_numbers = []
                    this.mailboxesToSave = this.selectedVoicemail && this.selectedVoicemail.mailboxesToSave ? this.selectedVoicemail.mailboxesToSave : null

                    if (this.selectedGroup && this.selectedGroup.voicemail && this.selectedGroup.voicemail.additional_numbers && this.selectedGroup.voicemail.additional_numbers.length) {
                        this.selectedGroup.voicemail.additional_numbers.map(n => {
                                    
                            n.voicemail.defaultPrompts = this.copyPrompts(true)

                            this.additional_numbers.push(
                                {
                                    phone_number: n.number,
                                    voicemail: n.voicemail,
                                }
                            )
                        })
                    }
                    this.loadMailboxes()
                });
            } else {
                this.$nextTick(() => {
                    this.selectedMenu = null
                    this.metadataToSave = null
                    this.notificationSMSNumber = null
                    this.notificationEmailAddress = null
                    this.metadata = []
                    this.menus = []
                    this.allMailboxes = []
                });                
            }

            this.refreshKey++
        },
    }
}

</script>

<style scoped>

</style>
