<template>
  <v-dialog
      v-model="value"
      persistent
      :max-width="maxWidth"
      >
        <v-form
            ref="frmValidatePayment"
            @submit.prevent="doMakePayment"
            v-model="isValid"
        >

          <v-card>
              <v-card-title class="text-h5">
                Pay Invoice
              </v-card-title>

              <v-card-text>

                <v-text-field
                  label="Amount"
                  light dense flat
                  v-model="amount"
                  background-color="white"              
                  type="number"
                  :rules="[requiredRule, minRule, maxRule]"
                /> 
                <v-textarea
                  label="Payment Note"
                  light
                  dense
                  flat
                  auto-grow
                  rows="1"
                  v-model="note"
                  background-color="white"              

                /> 

                <payment-profile-select
                  v-model="selectedPaymentProfile"
                  :paymentProfiles="paymentProfiles"
                  :rules="[requiredRule]"
                />
              </v-card-text>

            <v-card-subtitle
            >
              <div
                class="error white--text"
                v-if="error"
              >
                {{error}}
              </div>
              <div
                v-else
              >
                &nbsp;
              </div>
            </v-card-subtitle>


              <v-card-actions>
                <v-spacer></v-spacer>
                  <v-btn        
                    color="primary"            
                    type="submit"
                    :loading="sending"
                    :disabled="!isValid"
                  >                    
                  OK
                  </v-btn>
                  <v-btn
                      @click="cancel"
                      :disabled="sending"
                  >
                    Cancel
                  </v-btn>
              </v-card-actions>
          </v-card>
        </v-form>
  </v-dialog>

</template>

<script>

  import {mapActions} from "vuex";
  import paymentProfileSelect from "@/components/paymentProfileSelect";

  export default {
    props: {
      value: {
        default: false,
        type: Boolean
      },
      'max-width': {
        default: '500',
        type: String
      },
      invoices: {
        default: () => [],
        type: Array 
      },
      group_id: {
        type: String,
        default: null
      }
    },
    components: {
      'payment-profile-select': paymentProfileSelect
    },
    data: () => ({
      selectedPaymentProfile: '',
      customerProfile: {},
      amount: null,
      note: null,
      error: '',
      sending: false,
      isValid: false
    }),
    mounted() {
    },
    methods: {
      ...mapActions('billing', ['loadCustomerProfile','makePayment']),
      minRule (v) {
        return v > this.minPayment ? true : `Value must be greater or equal to ${this.minPayment}`
      },
      maxRule (v) {
        return v > this.maxPayment ? `Value must be less than or equal to ${this.maxPayment}` : true
      },      
      requiredRule (v) {
        return !!v || 'Required.'
      },
      roundToPennies (value) {
        let val = (value * 100) + .5
        return Math.floor(val) / 100 
      },
      cancel () {
        this.$emit('cancel')
      },
      async doLoadCustomerProfile () {
        let profile = await this.loadCustomerProfile({group_id: this.group_id})
        this.customerProfile = profile
        let amount = 0
        this.invoices.map(i => {
          amount += i.amount_due
        })
        this.amount = this.roundToPennies(amount)
      },
      convertPaymentProfile (p) {

        let profile = {
          paymentProfileId: p.paymentProfileId,
          customerProfileId: p.customerProfileId,
          billTo: p.billTo,
          type: p.type
        }
        if (p && p.parameters && p.parameters.length) {
          p.parameters.map(par => {
            profile[par.id] = par.value
          })
        }
        return profile
      },
      async doMakePayment () {
        this.sending = true
        this.error = ''

        let pm = await this.makePayment({
          group_id: this.group_id,
          amount: this.amount,
          invoiceNumbers: JSON.stringify(this.invoiceNumbers),
          paymentProfileId: this.selectedPaymentProfile,
          note: this.note 
        })
        this.sending = false

        if (pm && pm.error) {
          this.error = pm.error.message ? pm.error.message : 'An error occurred'
        }
        this.$emit('success', {
          invoices: pm.invoices,
          invoiceNumbers: JSON.stringify(this.invoiceNumbers)
        })
      }
    },
    computed: {
      paymentProfiles () {
        let ret = []
        if (this.customerProfile && this.customerProfile.paymentProfiles && this.customerProfile.paymentProfiles.length) {
          this.customerProfile.paymentProfiles.map(p => {
            ret.push(this.convertPaymentProfile(p))
          })
        }
        return ret
      },
      minPayment () {
        debugger
        let arr = []
        if (this.invoices && this.invoices.length) {
          this.invoices.map(i => {
            arr.push(i.amount_due)
          })
        }
        let sorted = arr.sort( (a,b) => {
                return a < b ? -1 : a > b ? 1 : 0
            })
        debugger
        sorted[sorted.length-1] = 0
        let sum = 0
        sorted.map(s => {
          sum += s
        })
        sum += 0.01
        return this.roundToPennies(sum)
      },
      maxPayment () {
        let sum=0
        if (this.invoices && this.invoices.length) {
          this.invoices.map(i => {
            sum += i.amount_due
          })
        }
        return this.roundToPennies(sum)
      },
      invoiceNumbers () {
        let ret = []
        if (this.invoices && this.invoices.length) {
          this.invoices.map(i => {
            ret.push(i.number)
          })
        }
        return ret
      }

    },
    watch: {
      value (newValue, oldValue) {
        this.selectedPaymentProfile = ''
        this.amount = null
        this.note = null
        this.error = ''

        if (newValue) {
          this.doLoadCustomerProfile()
        } else {
          this.customerProfile = {}
        }
      }
    }
  }
</script>

