<template>

    <div class="d-flex fill-height ycmd-dark-blue full-width pa-2 br-2">

      <loading :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      :background-color="`#101622`"
      :color="`#637eb6`"
      :loader="`bars`">
      </loading>

        <div class="d-flex fill-height full-width flex-column">

            <div class="d-flex align-center white--text pa-2 pb-3">

                <div class="fs-16 fw-500">FORGOT</div>
                <v-spacer />
                <v-btn dark icon text @click="route('login', 'prev')">
                    <v-icon>close</v-icon>
                </v-btn>
            </div>

            <div class="d-flex flex-column fill-height full-width">

                <div class="d-flex flex-column flex-grow-1 ycmd-medium-blue full-width pa-3 br-2">

                    <v-layout v-if="!success" shrink full-width>

                        <v-text-field light solo dense flat v-model="email" class="pt-3"
                                      name="input-email"
                                      label="Email"
                                      hint="At least 8 characters"
                                      v-on:keyup.enter="resetPassword"/>

                    </v-layout>

                    <v-layout shrink column v-if="errorMessage">

                        <div style="background-color: red; color: white; padding: 5px;">{{errorMessage}}</div>

                    </v-layout>

                    <v-layout shrink column v-if="success">

                        <div class="pl-1 white--text">Successfully sent code! Please enter code and new password below.</div>

                        <v-form
                                ref="passwordResetForm"
                                v-model="validPasswordReset"
                                :lazy-validation="false"
                                class="pt-3">
                            <v-text-field
                                    v-model="resetCode"
                                    light flat single-line dense solo
                                    :rules="[rules.required]"
                                    label="Code"
                                    color="#ffffff"
                                    :tabindex="1"
                                    outlined />

                            <v-text-field
                                    v-model="newPassword"
                                    light flat single-line dense solo
                                    :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                    :rules="[rules.required, rules.min]"
                                    :type="showPassword ? 'text' : 'password'"
                                    label="New Password"
                                    color="#ffffff"
                                    outlined
                                    counter
                                    :tabindex="2"
                                    @input="extraPasswordRulesCheck()"
                                    @click:append="showPassword = !showPassword"/>

                            <v-text-field
                                    v-model="newPasswordConfirm"
                                    class="pt-2"
                                    light flat single-line dense solo
                                    :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
                                    :rules="[rules.required, rules.min]"
                                    :type="showConfirmPassword ? 'text' : 'password'"
                                    label="Confirm Password"
                                    color="#ffffff"
                                    outlined
                                    counter
                                    :tabindex="3"
                                    v-on:keyup.enter="resetPasswordConfirm"
                                    @input="extraPasswordRulesCheck()"
                                    @click:append="showConfirmPassword = !showConfirmPassword" />
                        </v-form>

                        <v-layout shrink column v-if="newPasswordError">

                            <div class="pl-1" style="color: #ff3333;">{{ newPasswordError }}</div>

                        </v-layout>

                    </v-layout>
                </div>

                <div class="d-flex flex-column align-center">

                    <div v-if="!success" class="full-width pa-1 pl-0 pr-0">
                        <v-btn dark block large depressed color="ycmd-green" :class="`${ !customValidateCheckBegin() ? 'disabled' : '' }`" @click="resetPassword">Send</v-btn>
                    </div>

                    <div v-else class="full-width pa-1 pl-0 pr-0">
                        <v-btn dark block large depressed color="ycmd-green" :class="`${ !customValidateCheckContinue() ? 'disabled' : '' }`" @click="resetPasswordConfirm">Reset</v-btn>
                    </div>

                    <div class="pt-2">
                        <v-btn dark text depressed color="white" class="fade-2" @click="route('authFAQ', 'up')">Having Trouble?</v-btn>
                    </div>
                </div>
            </div>
        </div>

        <v-dialog persistent v-model="confirmSuccess" max-width="330" style="">

          <v-card color="" dark style="background-color: #09283F; padding-top: 15px;">
            <v-card-text class="fs-18">
              Successfully changed password!
            </v-card-text>


            <v-card-actions>
              <v-spacer/>
              <v-btn text color="ycmd-dark-blue" @click="() => {this.route('login', 'fade-in-transition')}">Continue</v-btn>
              <v-spacer/>
            </v-card-actions>
          </v-card>

        </v-dialog>

    </div>

</template>

<script>

    import { defaultErrorHandler } from '../../graphql/handler/errorHandler';
    import { refreshHandler } from '../../graphql/handler/refreshHandler';

    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    import FORGOT_PASSWORD from '../../graphql/mutations/authenticateForgotPassword';
    import FORGOT_PASSWORD_CONFIRM from '../../graphql/mutations/authenticateForgotPasswordConfirm';

    export default {
        name: 'login',
        data: () => ({
            email: '',
            challengeName: '',
            errorMessage: '',
            success: false,
            confirmSuccess: false,
            error: null,
            validationMessage: '',
            isLoading: false,

            validPasswordReset: false,
            newPassword: '',
            newPasswordConfirm: '',
            showPassword: false,
            showConfirmPassword: false,
            newPasswordError: '',
            passwordsMatch: false,
            resetCode: '',

            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                emailMatch: () => ('The email and password you entered don\'t match')
            }
        }),
        components: {
          Loading
        },
        mounted() {
          this.success = false;
          this.confirmSuccess = false;
          this.errorMessage = '';
          this.newPasswordError = '';
        },
        apollo: {
            $client: 'publicClient'
        },
        methods: {
            extraPasswordRulesCheck() {
              // check if passwords match
              if (this.newPassword !== this.newPasswordConfirm) {
                this.newPasswordError = 'Passwords do not match.'
                this.passwordsMatch = false;
              } else {
                this.newPasswordError = '';
                this.passwordsMatch = true;
              }
            },
            customValidateCheckBegin() {
              if (this.email) {
                return true;
              }
              return false;
            },
            customValidateCheckContinue() {
              if (this.passwordsMatch && this.validPasswordReset) {
                return true;
              }
              return false;
            },
            async resetPassword() {
                // Check if form is valid
                if (!this.customValidateCheckBegin()) return;

                // We are setting the accesstoken to null here because we don't want access token header to send for login
                localStorage.setItem("accessToken", null);


                this.isLoading = true;

                this.success = false;
                this.errorMessage = '';

                // console.log('USING: ', this.email);

                try {
                    const response = await this.$apollo.mutate({
                        mutation: FORGOT_PASSWORD,
                        variables: {
                          email: this.email
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    // console.log('forgot password response: ', response);

                    this.isLoading = false;

                    if (response.data.authenticateForgotPassword) {
                      this.success = true;
                    } else if (response.errors && response.errors.length > 0) {
                      if (response.errors[0].message) {
                        this.errorMessage = response.errors[0].message;
                      } else {
                        this.newPasswordError = "Error 2E. Invalid response.";
                      }
                    } else {
                      // data did not come back
                      console.log('loginStart data did not come back: ', response);
                      this.errorMessage = 'Server error';
                    }
                } catch (e) {
                    this.isLoading = false;
                    this.errorMessage = 'Server error';
                    console.log('CAUGHT: There was an error in authenticateForgotPassword : ', e);
                    defaultErrorHandler(e);
                }
            },
            async resetPasswordConfirm() {
                // Check if form is valid
                if (!this.customValidateCheckContinue()) return;

                this.confirmSuccess = false;
                this.newPasswordError = '';

                // We are setting the accesstoken to null here because we don't want access token header to send for login
                localStorage.setItem("accessToken", null);

                this.isLoading = true;

                try {
                    const response = await this.$apollo.mutate({
                        mutation: FORGOT_PASSWORD_CONFIRM,
                        variables: {
                          email: this.email,
                          newpassword: this.newPasswordConfirm,
                          confirmationCode: this.resetCode
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    // console.log('forgot password confirm response: ', response);

                    this.isLoading = false;

                    if (response.data.authenticateForgotPasswordConfirm) {
                      this.confirmSuccess = true;
                    } else if (response.errors && response.errors.length > 0) {
                      if (response.errors[0].message.length > 20) {
                        this.newPasswordError = response.errors[0].message.substring(0, 20);
                      } else {
                        this.newPasswordError = response.errors[0].message;
                      }
                    } else {
                      // data did not come back
                      this.newPasswordError = 'Server error';
                      console.log('loginStart data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoading = false;
                    this.newPasswordError = 'Server error';
                    console.log('CAUGHT: There was an error in authenticateForgotPassword : ', e);
                    defaultErrorHandler(e);
                }
            },
            route(name, direction, query) {

                this.$router.push({
                    name: name, params: { transition: direction }, query
                }).catch(err => { console.log('router error: ', err) });
            }
        },
        computed: {
            errorTitle: function () {
            }
        }
    }

</script>

<style scoped>



</style>
