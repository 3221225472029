import gql from 'graphql-tag'

export default gql`query ycmdReportRun($report_id: ID, $parameters: [IdValueInput], $output_type: OutputType) {
	ycmdReportRun(report_id: $report_id, parameters: $parameters, output_type: $output_type) {
		user_id
		type
		activity_date
		activity_date_end
		job_id
		error_message
		report {
			report_name
			report_id
			properties {
				name
				id
				value
			}
			url
			columns {
				label
				field
				type
				width
			}
			lines
		}
	}
}`
